<template>
  <div>
    <div class="flix-row">
      <div class="flix-col-md-12">
        <h2 class="flix-html-h2">{{ $t('message.personalData') }}</h2>
      </div>
      <div class="flix-col-md-12">
        <div class="flix-form-group">
          <myEmail :data="data" :callback="function(data){setEmail(data)}" />
        </div>
      </div>
    </div>
    <div class="flix-row">
      <div class="flix-col-md-12">
        <h2 class="flix-html-h2">{{ $tc('message.settings', 2) }}</h2>
      </div>
      <div class="flix-col-md-12">
        <myLocation :data="data" :callback="function(data){setLocation(data)}" />
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <anonymBookings :data="data" :callback="function(data){setAnonym(data)}" />
        </div>
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <commentsBookings :data="data" :callback="function(data){setComments(data)}" />
        </div>
      </div>
    </div>
    <div class="flix-form-group">
      <div class="flix-row">
        <div class="flix-col-md-6">
          <div class="flix-form-group">
            <lastMinuteBookings :data="data" :callback="function(data){setLastMinute(data)}" />
          </div>
        </div>
        <div class="flix-col-md-6">
          <div class="flix-form-group">
            <password :data="data" :callback="function(data){setPassword(data)}" />
          </div>
        </div>
      </div>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" :key="data.email" v-if="data.email && $checkEmail(data.email)">
        <saveBtn :onClick="function () { saveData() }"><template v-slot:text>{{ $t('message.save', {name: $t('message.additionalSettings')}) }}</template></saveBtn>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    lastMinuteBookings () { return import('@/components/assistent/components/datepicker/lastminute') },
    anonymBookings () { return import('@/components/assistentPrivate/components/anonymize') },
    commentsBookings () { return import('@/components/assistentPrivate/components/comments') },
    myEmail () { return import('@/components/assistentPrivate/components/email') },
    myLocation () { return import('@/components/assistentPrivate/components/location') },
    password () { return import('@/components/assistentPrivate/components/password') }
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      lastminute: this.getLastMinute()
    }
  },
  methods: {
    setLocation (data) {
      this.data.location = data
    },
    setEmail (data) {
      this.data.email = data
    },
    setComments (data) {
      this.data.comments = data
    },
    setPassword (data) {
      this.data.password = data
    },
    setAnonym (data) {
      this.data.exclude.anonym = data
    },
    getLastMinute () {
      var selected = 0

      if (typeof this.data.exclude.lastminute !== 'undefined') {
        selected = this.data.exclude.lastminute * 1
      }
      return selected
    },
    setLastMinute (data) {
      this.lastminute = data
    },
    saveData () {
      var data = {}
      data.exclude = Object.assign(this.data.exclude, { lastminute: this.lastminute })
      this.callback(data)
    }
  }
}
</script>
